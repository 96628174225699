var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-container",
            [
              _c("div", { staticClass: "mb-2" }, [
                _c("h2", { staticClass: "pa-3" }, [
                  _vm._v("Histórico de Status")
                ])
              ]),
              _c(
                "v-card",
                { attrs: { flat: "", solo: "", outlined: "" } },
                _vm._l(_vm.historicotitulo, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id_historico,
                      staticClass: "ma-2 d-block",
                      attrs: { "data-cy": "listagemHistoricoTitulo" }
                    },
                    [
                      _c("b", [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.dt_arquivo_movimento
                                ? _vm.dtMovimentoFormat(
                                    item.dt_arquivo_movimento
                                  )
                                : item.dt_historico
                            ) +
                            " "
                        )
                      ]),
                      _vm._v(" - " + _vm._s(_vm.historicoStatus(item)) + " "),
                      _c(
                        "v-tooltip",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.user_id,
                              expression: "item.user_id"
                            }
                          ],
                          attrs: { top: "", width: "300", "max-width": "350" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: item.user_id,
                                                expression: "item.user_id"
                                              }
                                            ],
                                            staticClass: "mx-2",
                                            attrs: {
                                              elevation: "0",
                                              "x-small": ""
                                            }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(" Detalhes "),
                                        _c(
                                          "v-icon",
                                          { attrs: { "x-small": "" } },
                                          [_vm._v("mdi-information-outline")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " Ação solicitada pelo usuário: " +
                                _vm._s(item.ds_login) +
                                " "
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-icon",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.mostrarDebugButton,
                              expression: "item.mostrarDebugButton"
                            }
                          ],
                          attrs: { small: "" },
                          on: {
                            click: function($event) {
                              _vm.modificaestado(
                                _vm.historicotitulo.indexOf(item)
                              )
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.botao_mostra_detalhes))]
                      ),
                      _c(
                        "v-container",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.vshow,
                              expression: "item.vshow"
                            }
                          ]
                        },
                        [
                          _c("div", [
                            _vm._v(
                              " " + _vm._s(_vm.protocoloHistorico(item)) + " "
                            )
                          ]),
                          _c("div", [
                            _vm._v(" " + _vm._s(_vm.historicoDebug(item)) + " ")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }